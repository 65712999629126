
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Structure',
  methods: {
    getColumnClass(count: number) {
      return `lg:grid-cols-${count}`;
    }
  },
  data() {
    return {
      structure: [
        {
          name: 'Legend',
          ranks: [
            [
              {
                name: 'Leader'
              }
            ],
            [
              {
                name: 'General'
              }
            ],
            [
              {
                name: 'Lietenant'
              }
            ],
            [
              {
                name: 'Sergeant'
              }
            ],
            [
              {
                name: 'Specialist'
              }
            ],
            [
              {
                name: 'Private'
              }
            ]
          ]
        },
        {
          name: 'Tactical',
          ranks: [
            [
              {
                name: 'Warlord',
                icon: require('@/assets/koga-eclipse-logo.gif')
              }
            ],
            [
              {
                name: 'War Commander',
                icon: require('@/assets/koga-eclipse-logo.gif')
              }
            ],
            [
              {
                name: 'Battle Commander',
                icon: require('@/assets/koga-eclipse-logo.gif')
              }
            ],
            [
              {
                name: 'Battle Sergeant',
                icon: require('@/assets/koga-eclipse-logo.gif')
              }
            ],
            [
              {
                name: 'Specialist',
                icon: require('@/assets/koga-eclipse-logo.gif')
              }
            ]
          ]
        },
        {
          name: 'Political',
          ranks: [
            [
              {
                name: 'Clan Leader',
                icon: require('@/assets/koga-eclipse-logo.gif')
              }
            ],
            [],
            [
              {
                name: 'Ambassador',
                icon: require('@/assets/koga-eclipse-logo.gif')
              }
            ],
            [
              {
                name: 'Diplomat',
                icon: require('@/assets/koga-eclipse-logo.gif')
              },
              {
                name: 'Cartographer',
                icon: require('@/assets/koga-eclipse-logo.gif')
              }
            ]
          ]
        },
        {
          name: 'Operations',
          ranks: [
            [
              {
                name: 'Operations Leader',
                icon: require('@/assets/koga-eclipse-logo.gif')
              }
            ],
            [
              {
                name: 'Community Manager',
                icon: require('@/assets/koga-eclipse-logo.gif')
              }
            ],
            [],
            [
              {
                name: 'Drill Sergeant',
                icon: require('@/assets/koga-eclipse-logo.gif')
              }
            ],
            [],
            [
              {
                name: 'Private',
                icon: require('@/assets/koga-eclipse-logo.gif')
              }
            ]
          ]
        }
      ]
    };
  }
});
